<template>
  <main>
    <div class="error no-print" v-if="!hasCameraSupport">
      Device does not support camera.
      <p v-if="errorMsg">{{errorMsg}}</p>
    </div>
    <div v-else>
      <h1 class="no-print">Boliden Fotobås</h1>
      <div class="video-stream no-print">
        <video autoplay playsinline ref="videoStream"></video>
      </div>
      <button
        :class="{'clicked' : buttonActive }"
        class="no-print"
        @mousedown="buttonIsActive"
        @mouseup="buttonIsInactive"
        @click="takeScreenshot">Ta en bild</button>

      <div class="screenshot">
        <h2 class="no-print" v-if="screenshotCaptured">Kolla vad fin du är!</h2>
        <canvas class="no-print" ref="screenshotCanvas"></canvas>
        <button class="no-print" v-if="screenshotCaptured" @click="printImage">Printa mig!</button>
        <div class="printable">
          <img ref="screenshotImg" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      buttonActive: false,
      hasCameraSupport: false,
      videoDevice: null,
      stream: null,
      errorMsg: '',
      screenshotCaptured: false,
      mediaConstraints: {
        video: {
          width: {
            min: 1280,
            ideal: 1920,
            max: 2560,
          },
          height: {
            min: 720,
            ideal: 1080,
            max: 1440,
          },
          facingMode: 'user',
        },
      },
    };
  },
  methods: {
    buttonIsActive() {
      this.buttonActive = true;
    },
    buttonIsInactive() {
      this.buttonActive = false;
    },
    takeScreenshot() {
      const canvas = this.$refs.screenshotCanvas;
      const video = this.$refs.videoStream;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      canvas.getContext('2d').drawImage(video, 0, 0);

      this.$refs.screenshotImg.src = canvas.toDataURL('image/webp');

      this.screenshotCaptured = true;
    },
    printImage() {
      // const dataURL = this.$refs.screenshotCanvas;
      window.print();
    },
    getCamera() {
      console.log('Getting available devices…');
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          const availableDevices = devices.filter((device) => device.kind === 'videoinput');
          if (availableDevices.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.videoDevice = availableDevices[0];
            console.log('Video device is:', this.videoDevice.label);

            const updatedConstraints = {
              ...this.mediaConstraints,
              deviceId: {
                exact: this.videoDevice.deviceId,
              },
            };
            this.startStream(updatedConstraints);
          }
        });
    },
    startStream(constraints) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then((mediaStream) => {
          this.stream = mediaStream;
          this.hasCameraSupport = true;
          this.attachVideo();
        })
        .catch((error) => {
          this.hasCameraSupport = false;
          this.errorMsg = error;
        });
    },
    attachVideo() {
      this.$nextTick(() => {
        this.$refs.videoStream.srcObject = this.stream;
        this.$refs.videoStream.play();
      });
    },
  },
  mounted() {
    // From https://www.digitalocean.com/community/tutorials/front-and-rear-camera-access-with-javascripts-getusermedia.amp
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      this.getCamera();
    }
  },
};
</script>
